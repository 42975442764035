.EditorTheme__ltr {
  text-align: left;
}

.EditorTheme__rtl {
  text-align: right;
}

.EditorTheme__paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.EditorTheme__paragraph:last-child {
  margin-bottom: 0;
}

.EditorTheme__ol,
.EditorTheme__ul {
  padding: 0;
  margin: 0;
}

.EditorTheme__ol {
  list-style-type: decimal;
}

.EditorTheme__ol ol {
  list-style-type: upper-alpha;
}

.EditorTheme__ol ol ol {
  list-style-type: upper-roman;
}

.EditorTheme__ol ol ol ol {
  list-style-type: lower-alpha;
}

.EditorTheme__ol ol ol ol ol {
  list-style-type: lower-roman;
}

.EditorTheme__ol ol ol ol ol ol {
  list-style-type: lower-greek;
}

.EditorTheme__ul {
  list-style: disc outside;
}

.EditorTheme__ul ul {
  list-style: square outside;
}

.EditorTheme__ul ul ul {
  list-style: circle outside;
}

.EditorTheme__ul ul ul ul {
  list-style: disc outside;
}

.EditorTheme__ul ul ul ul ul {
  list-style: square outside;
}

.EditorTheme__ul ul ul ul ul ul {
  list-style: circle outside;
}

.EditorTheme__listItem {
  margin: 0 32px 8px;
}

.EditorTheme__nestedListItem {
  list-style-type: none;
}

.EditorTheme__nestedListItem:before,
.EditorTheme__nestedListItem:after {
  display: none;
}

.EditorTheme__textBold {
  font-weight: bold;
}

.EditorTheme__textItalic {
  font-style: italic;
}

.EditorTheme__textUnderline {
  text-decoration: underline;
}
